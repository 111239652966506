export const headerHeight = "80px"
export const mobiHeaderHeight = "80px"
export const footerHeight = "60vh"

export const theme = {
  cs_yellow: `#FFCF00`,
  cs_blue: `#184763`,
  cs_dark_blue: `#333D46`,
  cs_lighter_blue: `#F6F9FC`,
  cs_darker_blue: `#1b1a20`,
  cs_light_gray: `#545357`,
  cs_purple: `#534491`
}
