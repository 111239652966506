import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { AppBar, Toolbar, IconButton, Badge, Menu, MenuItem, Button, Avatar, ButtonBase } from "@material-ui/core"
import Logo from "./image-components/logo"
import Close from "@material-ui/icons/Close"
import { useSpring, animated } from "react-spring"
import { mobiHeaderHeight, headerHeight, theme } from "../common/site-variables"
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import AccountIcon from '@material-ui/icons/AccountCircle';

// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { jsx, css } from "@emotion/core"
import { flexRowCenterAll } from "../common/format-styles"
import { AppUser } from '../data/app-user-model'
import { signOut } from '../services/auth-service'
import { toCamelCase } from "../helper/to-camel-case"
import { getCurrentUser } from '../services/auth-service';
import AuthContext from "../data/auth-context"
import { getFileURL } from "../services/product-service"

const links = [
  { name: "Products", href: "/products" },
  { name: "Services", href: "/services" },
  { name: "Company", href: "/company" },
]

interface PropTypes {
  isMobile: boolean;
  dashboard?: boolean;
  onSidebarOpen?: () => void;
}

const Header = (props: PropTypes) => {
  const { isMobile, dashboard, onSidebarOpen } = props
  const [anchorEl, setAnchorEl] = React.useState(null);
  const authState = React.useContext(AuthContext);

  useEffect(() => {
    !authState.dbUser && getCurrentUser().then(
      res => {
        res.data && authState.updateDbUser(res.data as AppUser);
        console.log(res.data);
      }, errors => {
        console.log(errors);
      }
    )
        
  }, [])

  const styles = css(`
    height: 80px;
    background-color: white;

    .toolBar {
      height: 100%;
      justify-content: space-between;

      .links {
        width: 20%;
        display: flex;
        justify-content: space-between;

        .link {
          text-decoration: none;

          &:hover {
            color: ${theme.cs_blue};
          }
        }
        
        .avatar {
          img {
            margin: auto;
          }
        }
      }

      button {
        color: ${theme.cs_dark_blue};
      }
    }

    .dropdown {
      position: absolute;
      width: inherit;
      height: calc(100vh - ${isMobile ? mobiHeaderHeight : headerHeight});
      left: 0;
      top: ${isMobile ? mobiHeaderHeight : headerHeight};
      background: ${theme.cs_yellow};
      z-index: -1;
      padding: 0  0 5% 0;
      transform-origin: top;

      display: ${isMobile ? "flex" : "none"};
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      .dashIcons {
        color: #1A2330;
      }

      a {
        color: #1A2330;
        font-size: 2rem;
        text-decoration: none;
      }
    }
  `);

  const [isOpen, setOpen] = React.useState(false)
  const [spring, setSpring] = useSpring(() => ({
    opacity: 0,
    transform: "scaleY(0)",
  }));

  const toggleMenu = () => {
    setOpen(!isOpen)
    setSpring({
      opacity: isOpen ? 0 : 1,
      transform: `scaleY(${isOpen ? 0 : 1})`,
    })
  }

  const [notifications, setNotifications] = React.useState([]);

  const handleSignout = () => {
    signOut().then(
      () => window.location.reload()
    );
  }

  const handleProfileMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar css={styles}>
      <Toolbar className="toolBar">
        <Link to="/">
          <Logo logoType="fixed" />
        </Link>

        {
          (!isMobile) &&
          <div className="links">
            {links.map((link) => (
              <Link className="link" to={link.href} key={link.href}>
                {link.name}
              </Link>
            ))}

            {authState.dbUser ?
            <React.Fragment>
                  
              <ButtonBase aria-controls="profile_menu" aria-haspopup="true" onClick={handleProfileMenuClick}>
                <Avatar className="avatar" src={getFileURL(authState.dbUser.avatar, authState.dbUser.token)} />
              </ButtonBase>
              
              <Menu
                id="profile_menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleProfileMenuClose}
              >
                <MenuItem onClick={handleProfileMenuClose}>
                  <Link to="/dashboard">
                    Dashboard
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleProfileMenuClose}>My account</MenuItem>
                <MenuItem onClick={handleProfileMenuClose}>Logout</MenuItem>
              </Menu>
            </React.Fragment> 
            :
            <Link to="/signin">
              Sign in
            </Link>
          
          }

          </div>
        }

        {
          (!dashboard && isMobile) &&
          <div className="menuBtn">
            <IconButton onClick={toggleMenu}>
              {" "}
              {isOpen ? <Close /> : <MenuIcon />}{" "}
            </IconButton>
          </div>
        }

        {
          dashboard &&
          <div>
            { !isMobile ?
            <IconButton color="inherit">
              <Badge
                badgeContent={notifications?.length}
                color="primary"
                variant="dot"
              >
                <NotificationsIcon htmlColor="#1A2330" />
              </Badge>
            </IconButton>
              :
              <IconButton
                color="inherit"
                onClick={onSidebarOpen}
              >
                <MenuIcon htmlColor="#1A2330" />
              </IconButton>
            }
          </div>
        }
      </Toolbar>

      <animated.div style={spring} className="dropdown">
        {links.map((link) => (
          <Link className="link" to={link.href} key={link.href}>
            {link.name}
          </Link>
        ))}
      </animated.div>
    </AppBar>
  )
}

export default Header
