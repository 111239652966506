import DirectusSDK from '@directus/sdk-js';
import { apiUrl } from './auth-service';


const directus = new DirectusSDK(apiUrl);
const query: any = {};

export const getProduct = (productId: string) => {
    return directus.items('products').read(productId, {fields: '*.*'});
}

export const getProducts = (amount?: number, page?: number, categoryId?: string, featured?: boolean) => {
    const itemAmount = amount ? { limit: amount } : {};
    const pageNum = page ? { page: page } : {};
    const getByCategory = categoryId ?  {
        categories: {
            categories_id: {
                _eq: categoryId
            }
        }
    } : {};
    const getFeatured = featured ?  {
        featured: {_eq: true}
    } : {};

    return directus.items('products').read({
        ...query,
        ...itemAmount,
        ...pageNum,
        meta: '*',
        fields: '*.*',
        filter: {
            ...getFeatured,
            ...getByCategory
        }
    },);
}


export const getFileURL = (id: string, token?: string) => {
    return token ? `${apiUrl}/assets/${id}?access_token=${token}` : `${apiUrl}/assets/${id}`;
}

export const getCategories = () => {
    return directus.items('categories').read(query, {fields: 'id,title'});
}
